import React, { Component } from "react";

import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import * as Sentry from "@sentry/react";

import {
  Error404,
  InicioSesion,
  NuevaContrasena,
  PrivateRoute,
  // Registrate,
  Registro,
  RestablecerContrasena,
} from "./components/auth";
import {
  Alerta as AlertaLeyes,
  Alertas as AlertasLeyes,
  FavoritoTarea,
  Favoritos,
  Norma,
  Normas,
  Promulgadas,
  Publicadas,
} from "./components/leyes";
import {
  Expediente as ExpedienteMMA,
  Expedientes as ExpedientesMMA,
} from "./components/mma";
import {
  Equipo as EquipoVGC,
  Equipos as EquiposVGC,
  Medidas as MedidasVGC,
  Procedimientos as ProcedimientosVGC,
  Requerimientos as RequerimientosVGC,
} from "./components/teams";

import {
  Categorias,
  Dashboard as DashboardAplicabilidad,
  GenerarEntregables,
  Indefinidas,
  Permiso,
  PermisoInteligencia,
  PermisoInteligenciaTarea,
  PermisoTarea,
  Permisos,
  PermisosInteligencia,
  Preguntas,
  ProyectoInteligencia,
  Proyectos,
  Secciones,
  TablaRequisitos,
} from "./components/permitting";
import { TimelineRequisitos } from "./components/permitting/aplicabilidad";
// import { BaseConocimiento, Ticket, Tickets } from "./components/soporte";
import {
  Dashboard as DasboardTareas,
  EntregableNuevo,
  EquipoListado,
  ProyectoNuevo,
  Proyectos as ProyectosTareas,
  Tarea,
} from "./components/tareas";
import {
  Contrasena,
  Estadisticas,
  Inicio,
  Notificaciones,
} from "./components/usuario";
import {
  Alerta,
  Alertas,
  Documento,
  Documentos,
  Encargado as EncargadoSeia,
  Encargados as EncargadosSeia,
  Expediente,
  Expedientes,
  ExpedientesDocumentos,
  ExpedientesEstadisticas,
  ExpedientesEstadisticasGeneradoras,
  ExpedientesMapa,
  Favoritos as FavoritosCA,
  Fiscalizacion,
  Fiscalizaciones,
  FiscalizacionesEstadisticas,
  Hecho,
  Hechos,
  HechosEstadisticas,
  InstrumentoInfringido,
  InstrumentosInfringidos,
  Lite,
  MedidaProvisional,
  MedidasEstadisticas,
  MedidasProvisionales,
  Pertinencia,
  Pertinencias,
  PertinenciasEstadisticas,
  Procedimiento,
  Procedimientos,
  ProcedimientosEstadisticas,
  Programa,
  Programas,
  ProgramasEstadisticas,
  QuinencoDashboard,
  QuinencoExpedientes,
  QuinencoPertinencias,
  QuinencoUnidades,
  Rca,
  Rcas,
  RcasEstadisticas,
  Requerimiento,
  Requerimientos,
  Sancion,
  Sanciones,
  SancionesEstadisticas,
  SeguimientoAmbiental,
  SeguimientosAmbiental,
  Titular as TitularSeia,
  Titulares as TitularesSeia,
  Unidad,
  Unidades,
  UnidadesEstadisticas,
} from "./components/vigilancias";
import { cargarUsuario } from "./redux/actions/auth/action";
import store from "./redux/store";
import ScrollToTop from "./scrolltotop";

class App extends Component {
  render = () => {
    store.dispatch(cargarUsuario());
    return (
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            <Route exact path="/" element={<Navigate to="/inicio-sesion" />} />
            <Route
              exact
              path="/permitting-target/proyectos"
              element={<PrivateRoute component={Proyectos} />}
            />
            <Route
              exact
              path="/permitting-target/aplicabilidad/dashboard"
              element={<PrivateRoute component={DashboardAplicabilidad} />}
            />
            <Route
              exact
              path="/permitting-target/aplicabilidad/permisos"
              element={<PrivateRoute component={Permisos} />}
            />
            <Route
              exact
              path="/permitting-target/aplicabilidad/permiso"
              element={<PrivateRoute component={Permiso} />}
            />
            <Route
              exact
              path="/permitting-target/aplicabilidad/permiso/tarea"
              element={<PrivateRoute component={PermisoTarea} />}
            />
            <Route
              exact
              path="/permitting-target/aplicabilidad/requisitos"
              element={<PrivateRoute component={TablaRequisitos} />}
            />
            <Route
              exact
              path="/permitting-target/aplicabilidad/requisitos/timeline"
              element={<PrivateRoute component={TimelineRequisitos} />}
            />
            <Route
              exact
              path="/permitting-target/parametrizacion/secciones"
              element={<PrivateRoute component={Secciones} />}
            />
            <Route
              exact
              path="/permitting-target/parametrizacion/categorias"
              element={<PrivateRoute component={Categorias} />}
            />
            <Route
              exact
              path="/permitting-target/parametrizacion/preguntas"
              element={<PrivateRoute component={Preguntas} />}
            />
            <Route
              exact
              path="/permitting-target/parametrizacion/generar-entregables"
              element={<PrivateRoute component={GenerarEntregables} />}
            />
            <Route
              exact
              path="/permitting-target/parametrizacion/indefinidas"
              element={<PrivateRoute component={Indefinidas} />}
            />
            <Route
              exact
              path="/permitting-target/permisoteca/proyectos"
              element={<PrivateRoute component={ProyectoInteligencia} />}
            />
            <Route
              exact
              path="/permitting-target/permisoteca/permisos"
              element={<PrivateRoute component={PermisosInteligencia} />}
            />
            <Route
              exact
              path="/permitting-target/permisoteca/permiso"
              element={<PrivateRoute component={PermisoInteligencia} />}
            />
            <Route
              exact
              path="/permitting-target/permisoteca/permiso/tarea"
              element={<PrivateRoute component={PermisoInteligenciaTarea} />}
            />
            <Route
              exact
              path="/user/inicio"
              element={<PrivateRoute component={Inicio} />}
            />
            <Route
              exact
              path="/user/estadisticas"
              element={<PrivateRoute component={Estadisticas} />}
            />
            <Route
              exact
              path="/user/notificaciones"
              element={<PrivateRoute component={Notificaciones} />}
            />
            <Route
              exact
              path="/user/cambio-contrasena"
              element={<PrivateRoute component={Contrasena} />}
            />
            <Route
              exact
              path="/compliance-analytics/alertas/nueva"
              element={<PrivateRoute component={Alerta} />}
            />
            <Route
              exact
              path="/compliance-analytics/alertas"
              element={<PrivateRoute component={Alertas} />}
            />
            <Route
              exact
              path="/compliance-analytics/lite"
              element={<PrivateRoute component={Lite} />}
            />
            <Route
              exact
              path="/compliance-analytics/expedientes/listado"
              element={<PrivateRoute component={Expedientes} />}
            />
            <Route
              exact
              path="/compliance-analytics/expedientes/mapa"
              element={<PrivateRoute component={ExpedientesMapa} />}
            />
            <Route
              exact
              path="/compliance-analytics/expedientes/estadistica"
              element={<PrivateRoute component={ExpedientesEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/expedientes/documentos"
              element={<PrivateRoute component={ExpedientesDocumentos} />}
            />
            <Route
              exact
              path="/generadoras/estadistica"
              element={
                <PrivateRoute component={ExpedientesEstadisticasGeneradoras} />
              }
            />
            <Route
              exact
              path="/quinenco/unidades"
              element={<PrivateRoute component={QuinencoUnidades} />}
            />
            <Route
              exact
              path="/quinenco/dashboard"
              element={<PrivateRoute component={QuinencoDashboard} />}
            />
            <Route
              exact
              path="/quinenco/expedientes"
              element={<PrivateRoute component={QuinencoExpedientes} />}
            />
            <Route
              exact
              path="/quinenco/pertinencias"
              element={<PrivateRoute component={QuinencoPertinencias} />}
            />
            <Route
              exact
              path="/teams/equipos"
              element={<PrivateRoute component={EquiposVGC} />}
            />
            <Route
              exact
              path="/teams/equipo"
              element={<PrivateRoute component={EquipoVGC} />}
            />
            <Route
              exact
              path="/teams/medidas"
              element={<PrivateRoute component={MedidasVGC} />}
            />
            <Route
              exact
              path="/teams/procedimientos"
              element={<PrivateRoute component={ProcedimientosVGC} />}
            />
            <Route
              exact
              path="/teams/requerimientos"
              element={<PrivateRoute component={RequerimientosVGC} />}
            />
            <Route
              exact
              path="/compliance-analytics/expediente"
              element={<PrivateRoute component={Expediente} />}
            />
            <Route
              exact
              path="/compliance-analytics/seguimientos-ambiental/listado"
              element={<PrivateRoute component={SeguimientosAmbiental} />}
            />
            <Route
              exact
              path="/compliance-analytics/seguimiento-ambiental"
              element={<PrivateRoute component={SeguimientoAmbiental} />}
            />
            <Route
              exact
              path="/compliance-analytics/pertinencias/listado"
              element={<PrivateRoute component={Pertinencias} />}
            />
            <Route
              exact
              path="/compliance-analytics/pertinencias/estadistica"
              element={<PrivateRoute component={PertinenciasEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/pertinencia"
              element={<PrivateRoute component={Pertinencia} />}
            />
            <Route
              exact
              path="/compliance-analytics/titulares"
              element={<PrivateRoute component={TitularesSeia} />}
            />
            <Route
              exact
              path="/compliance-analytics/titular"
              element={<PrivateRoute component={TitularSeia} />}
            />
            <Route
              exact
              path="/compliance-analytics/encargados"
              element={<PrivateRoute component={EncargadosSeia} />}
            />
            <Route
              exact
              path="/compliance-analytics/encargado"
              element={<PrivateRoute component={EncargadoSeia} />}
            />
            <Route
              exact
              path="/compliance-analytics/procedimientos/listado"
              element={<PrivateRoute component={Procedimientos} />}
            />
            <Route
              exact
              path="/compliance-analytics/procedimientos/estadistica"
              element={<PrivateRoute component={ProcedimientosEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/procedimiento"
              element={<PrivateRoute component={Procedimiento} />}
            />
            <Route
              exact
              path="/compliance-analytics/unidades/listado"
              element={<PrivateRoute component={Unidades} />}
            />
            <Route
              exact
              path="/compliance-analytics/unidades/estadistica"
              element={<PrivateRoute component={UnidadesEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/unidad"
              element={<PrivateRoute component={Unidad} />}
            />
            <Route
              exact
              path="/compliance-analytics/hechos-infraccionales/listado"
              element={<PrivateRoute component={Hechos} />}
            />
            <Route
              exact
              path="/compliance-analytics/hechos-infraccionales/estadistica"
              element={<PrivateRoute component={HechosEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/hecho-infraccional"
              element={<PrivateRoute component={Hecho} />}
            />
            <Route
              exact
              path="/compliance-analytics/programas/listado"
              element={<PrivateRoute component={Programas} />}
            />
            <Route
              exact
              path="/compliance-analytics/programas/estadistica"
              element={<PrivateRoute component={ProgramasEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/programa"
              element={<PrivateRoute component={Programa} />}
            />
            <Route
              exact
              path="/compliance-analytics/favoritos"
              element={<PrivateRoute component={FavoritosCA} />}
            />
            <Route
              exact
              path="/compliance-analytics/sanciones/listado"
              element={<PrivateRoute component={Sanciones} />}
            />
            <Route
              exact
              path="/compliance-analytics/sanciones/estadistica"
              element={<PrivateRoute component={SancionesEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/sancion"
              element={<PrivateRoute component={Sancion} />}
            />
            <Route
              exact
              path="/compliance-analytics/medidas-provisionales/listado"
              element={<PrivateRoute component={MedidasProvisionales} />}
            />
            <Route
              exact
              path="/compliance-analytics/medidas-provisionales/estadistica"
              element={<PrivateRoute component={MedidasEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/medida-provisional"
              element={<PrivateRoute component={MedidaProvisional} />}
            />
            <Route
              exact
              path="/compliance-analytics/instrumentos-infringidos"
              element={<PrivateRoute component={InstrumentosInfringidos} />}
            />
            <Route
              exact
              path="/compliance-analytics/instrumento-infringido"
              element={<PrivateRoute component={InstrumentoInfringido} />}
            />
            <Route
              exact
              path="/compliance-analytics/fiscalizaciones/listado"
              element={<PrivateRoute component={Fiscalizaciones} />}
            />
            <Route
              exact
              path="/compliance-analytics/fiscalizaciones/estadistica"
              element={<PrivateRoute component={FiscalizacionesEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/fiscalizacion"
              element={<PrivateRoute component={Fiscalizacion} />}
            />
            <Route
              exact
              path="/compliance-analytics/documentos"
              element={<PrivateRoute component={Documentos} />}
            />
            <Route
              exact
              path="/compliance-analytics/documento"
              element={<PrivateRoute component={Documento} />}
            />
            <Route
              exact
              path="/compliance-analytics/requerimientos-ingreso"
              element={<PrivateRoute component={Requerimientos} />}
            />
            <Route
              exact
              path="/compliance-analytics/requerimiento-ingreso"
              element={<PrivateRoute component={Requerimiento} />}
            />
            <Route
              exact
              path="/compliance-analytics/rcas/listado"
              element={<PrivateRoute component={Rcas} />}
            />
            <Route
              exact
              path="/compliance-analytics/rcas/estadistica"
              element={<PrivateRoute component={RcasEstadisticas} />}
            />
            <Route
              exact
              path="/compliance-analytics/rca"
              element={<PrivateRoute component={Rca} />}
            />
            <Route
              exact
              path="/legal-tracking/normas"
              element={<PrivateRoute component={Normas} />}
            />
            <Route
              exact
              path="/legal-tracking/favoritos"
              element={<PrivateRoute component={Favoritos} />}
            />
            <Route
              exact
              path="/legal-tracking/norma"
              element={<PrivateRoute component={Norma} />}
            />
            <Route
              exact
              path="/legal-tracking/norma/tarea"
              element={<PrivateRoute component={FavoritoTarea} />}
            />
            <Route
              exact
              path="/legal-tracking/normas/promulgadas"
              element={<PrivateRoute component={Promulgadas} />}
            />
            <Route
              exact
              path="/legal-tracking/normas/publicadas"
              element={<PrivateRoute component={Publicadas} />}
            />
            {/* <Route
              exact
              path="/legal-tracking/estadisticas"
              element={<PrivateRoute component={NormasEstadisticas} />}
            /> */}
            <Route
              exact
              path="/legal-tracking/alertas/nueva"
              element={<PrivateRoute component={AlertaLeyes} />}
            />
            <Route
              exact
              path="/legal-tracking/alertas"
              element={<PrivateRoute component={AlertasLeyes} />}
            />
            <Route
              exact
              path="/legal-tracking/mma/expedientes"
              element={<PrivateRoute component={ExpedientesMMA} />}
            />
            <Route
              exact
              path="/legal-tracking/mma/expediente"
              element={<PrivateRoute component={ExpedienteMMA} />}
            />
            <Route
              exact
              path="/quality-tasking/tarea"
              element={<PrivateRoute component={Tarea} />}
            />
            <Route
              exact
              path="/quality-tasking/dashboard"
              element={<PrivateRoute component={DasboardTareas} />}
            />
            <Route
              exact
              path="/quality-tasking/equipo"
              element={<PrivateRoute component={EquipoListado} />}
            />
            <Route
              exact
              path="/quality-tasking/proyectos"
              element={<PrivateRoute component={ProyectosTareas} />}
            />
            <Route
              exact
              path="/quality-tasking/proyecto/nuevo"
              element={<PrivateRoute component={ProyectoNuevo} />}
            />
            <Route
              exact
              path="/quality-tasking/tarea/entregable"
              element={<PrivateRoute component={EntregableNuevo} />}
            />
            {/* <Route
              exact
              path="/soporte/base-conocimiento"
              element={<PrivateRoute component={BaseConocimiento} />}
            />
            <Route
              exact
              path="/soporte/tickets"
              element={<PrivateRoute component={Tickets} />}
            />
            <Route
              exact
              path="/soporte/ticket/:ticket"
              element={<PrivateRoute component={Ticket} />}
            /> */}
            <Route exact path="/inicio-sesion" element={<InicioSesion />} />
            {/* <Route exact path="/registrate" element={<Registrate />} /> */}
            <Route
              exact
              path="/restablecer-contrasena"
              element={<RestablecerContrasena />}
            />
            <Route
              exact
              path="/nueva-contrasena/:uid/:token"
              element={<NuevaContrasena />}
            />
            <Route exact path="/registro/:uid/:token" element={<Registro />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </BrowserRouter>
      </Provider>
    );
  };
}

export default Sentry.withProfiler(App);
