import React, { Component } from "react";

import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { bindActionCreators } from "redux";

import logo_small from "../../images/logo-small.png";
import logo from "../../images/logo.png";
import { cerrarSesion, estadoSidebar } from "../../redux/actions/auth/action";

export class Navbar extends Component {
  escondeMenu = (estado) => {
    const { estadoSidebar } = this.props;
    estadoSidebar(estado);
  };

  render = () => {
    const { cerrarSesion, user } = this.props;

    return (
      <div className="topbar">
        <div className="topbar-left">
          <Link to="/user/inicio" className="logo">
            <span>
              <img src={logo} alt="logo-lg" className="logo-lg" />
            </span>
            <span>
              <img
                src={logo_small}
                alt="logo-small"
                className="logo-sm d-none"
              />
            </span>
          </Link>
        </div>
        <nav className="navbar-custom">
          <ul className="list-unstyled topbar-nav float-end mb-0">
            <li className="dropdown notification-list">
              <span className="hidden-sm">
                <Link
                  className="nav-link dropdown-toggle arrow-none nav-user"
                  to="#"
                  role="button"
                  aria-haspopup="false"
                  aria-expanded="false"
                >
                  <span
                    className="ms-1 nav-user-name nombre_usuario"
                    data-tip
                    data-tooltip-id="tipo_plan"
                  >
                    {user && !user.is_freemium ? (
                      <span className="badge bg-success">Premium</span>
                    ) : (
                      <span className="badge bg-warning">Freemium</span>
                    )}
                  </span>
                  <span className="ms-1 nav-user-name nombre_usuario">
                    Hola {user && user.nombre ? user.nombre : "CL Usuario"}
                  </span>
                </Link>
              </span>
              <Tooltip id="tipo_plan">Tu plan en Cumplimiento Lean</Tooltip>
              <Tooltip id="cerrar_sesion">
                Cerrar sesión en Cumplimiento Lean
              </Tooltip>
              <Link
                data-tip
                data-tooltip-id="cerrar_sesion"
                className="nav-link dropdown-toggle arrow-none"
                to="#"
                onClick={cerrarSesion}
              >
                <i className="text-dark fa fa-power-off noti-icon"></i>
              </Link>
              <Tooltip id="soporte_cl">
                Contacta con soporte de Cumplimiento Lean
              </Tooltip>
              <Link
                data-tip
                data-tooltip-id="soporte_cl"
                className="nav-link dropdown-toggle arrow-none"
                to="https://wa.me/56930686476"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="text-success fab fa-whatsapp noti-icon"></i>
              </Link>
              {user &&
                !user.is_freemium &&
                user.dias_restantes &&
                user.dias_restantes > 0 && (
                  <span>
                    <Link
                      className="nav-link dropdown-toggle arrow-none"
                      to="#"
                      role="button"
                      aria-haspopup="false"
                      aria-expanded="false"
                      data-tip
                      data-tooltip-id="dias_restantes"
                    >
                      <i className="text-dark fa fa-clock noti-icon"></i>
                      <span className="badge bg-success noti-icon-badge">
                        {user.dias_restantes}
                      </span>
                    </Link>
                    <Tooltip id="dias_restantes">
                      Días restantes de tu plan Premium: {user.dias_restantes}
                    </Tooltip>
                  </span>
                )}
            </li>
          </ul>
          <ul className="list-unstyled topbar-nav mb-0">
            <li>
              <button
                data-tip
                data-tooltip-id="esconder_menu"
                className="button-menu-mobile nav-link"
                onClick={this.escondeMenu.bind(this, true)}
              >
                <i className="fa fa-bars"></i>
              </button>
              <Tooltip id="esconder_menu" aria-haspopup="true">
                Ocultar o mostrar el menú
              </Tooltip>
            </li>
          </ul>
        </nav>
      </div>
    );
  };
}

Navbar.propTypes = {
  cerrarSesion: PropTypes.func.isRequired,
  estadoSidebar: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ cerrarSesion, estadoSidebar }, dispatch);
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps, mapDispatchToProps)(Navbar);
